import { Location } from '@angular/common';
import { Inject, Injectable, Optional, TransferState, makeStateKey } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, first, map, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Domains } from '../../models/domains.model';
import { BaseResponse, HOST_ID } from '../../models/http.model';
import { HttpService } from '../http/http.service';

const CURRENT_HOST_KEY = makeStateKey<string>('currentHost');

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private domains: ReplaySubject<Domains> = new ReplaySubject();
  domains$ = this.domains.asObservable();
  mediaServer$ = this.domains$.pipe(
    first(),
    map(domains => domains.mediaServer)
  );

  private loadingState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingState$ = this.loadingState.asObservable();
  
  // TODO: update currentLanguage depends on url
  private currentLanguage = new ReplaySubject<string>();
  public currentLanguage$ = this.currentLanguage.asObservable();

  private backButtonViewToggle = new BehaviorSubject<boolean>(false);
  backButtonViewToggle$ = this.backButtonViewToggle.asObservable();

  private backButtonOnClick = new Subject<void>();
  backButtonOnClick$ = this.backButtonOnClick.asObservable();

  private maintenanceToggle: Subject<boolean> = new Subject();
  maintenanceToggle$ = this.maintenanceToggle.asObservable();

  constructor(
    private httpService: HttpService,
    private transferState: TransferState,
    @Optional() @Inject(HOST_ID) private currentHost: string,
    private location: Location
  ) { }

  private fetchDomains(): Observable<BaseResponse> {

    // means we're in client side
    // injection from server.ts cannot reach here
    if(this.currentHost == null) {
      this.currentHost = this.transferState.get<string>(CURRENT_HOST_KEY, '');

    } else {
      this.transferState.set(CURRENT_HOST_KEY, this.currentHost);
    }

    let baseUrl = environment.wapInitialBaseUrl;
    if( baseUrl == '') {
      // explicitly define the host for gateway access
      baseUrl = this.currentHost;
    }

    // this call is actually only made from server side
    // for the client side, it's already cached 
    return this.httpService.forEmptyBaseUrl().get(baseUrl + '/api/system/domains');
  }

  initializeDomains() {
    const initDone$: ReplaySubject<Domains> = new ReplaySubject();
    this.fetchDomains().subscribe(response => {
      if(!response.errors) {
        this.domains.next(response.data.domains);
        initDone$.next(response.data.domains);
      } else {
        initDone$.error(response.errors);
      }
    });

    return initDone$.asObservable().pipe(first());
  }

  showLoading() {
    this.loadingState.next(true);
  }

  hideLoading() {
    this.loadingState.next(false);
  }

  setLanguageFromUrl(url: string) {
    if(url.startsWith('/zh-cn/')) {
      this.currentLanguage.next('zh-cn');
    } else {
      this.currentLanguage.next('en-gb');
    }

    return this.currentLanguage$;
  }

  showBackButtonUntil(destroy$: Observable<void>, preventDefault: boolean = false) {
    this.backButtonViewToggle.next(true);
    this.backButtonOnClick.complete();

    // remove all past observers to prevent multiple subscription
    // if multiple showBackButton() is called
    this.backButtonOnClick = new Subject<void>();
    this.backButtonOnClick$ = this.backButtonOnClick.asObservable();

    destroy$.pipe(take(1)).subscribe(() => {
      this.backButtonViewToggle.next(false);
    });

    if (!preventDefault) {
      this.backButtonOnClick$.pipe(
        take(1)
      ).subscribe(() => {
        this.location.back();
      });
    }
    
    return this.backButtonOnClick$.pipe(take(1));
  }

  emitBackButtonClickSignal() {
    this.backButtonOnClick.next();
  }

  preloadImages(urls: string[]): void {
    if (typeof window !== 'undefined') {
      urls.forEach(url => {
        const img = new Image();
        img.src = url;
      });
    }
  }

  getWellBetUrl() {
    return this.httpService.forClientApi().get('/system/wellbet/url');
  }

  getMaintenanceStatus() {
    return this.httpService.forEmptyBaseUrl().get('/api/system/maintenance/status');
  }

  enableMaintenance() {
    this.maintenanceToggle.next(true);
  }

  disableMaintenance() {
    this.maintenanceToggle.next(false);
  }
}
