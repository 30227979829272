import { LocalizedMessagesBundle } from '../../models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'PASSWORD_STRENGTH_INFO_LENGTH_TEXT': '请输入长度【6-30】',
    'PASSWORD_STRENGTH_INFO_LETTER_TEXT': '支持字母(大小写皆可)',
    'PASSWORD_STRENGTH_INFO_NUMBER_TEXT': '支持数字',
    'PASSWORD_STRENGTH_INFO_SYMBOL_TEXT': '支持下列特殊字符：#@!~%^&*',
    'PASSWORD_STRENGTH_INVALID_TEXT': '无效的',
    'PASSWORD_STRENGTH_GOOD_TEXT': '良好',
    'PASSWORD_STRENGTH_STRONG_TEXT': '强'
  },
  'en-gb': {
    'PASSWORD_STRENGTH_INFO_LENGTH_TEXT': 'Length: 6-30 characters',
    'PASSWORD_STRENGTH_INFO_LETTER_TEXT': 'Letters (regardless capital or lowercase)',
    'PASSWORD_STRENGTH_INFO_NUMBER_TEXT': 'Numerical',
    'PASSWORD_STRENGTH_INFO_SYMBOL_TEXT': 'Allowed Symbol: #@!~%^&*',
    'PASSWORD_STRENGTH_INVALID_TEXT': 'Invalid',
    'PASSWORD_STRENGTH_GOOD_TEXT': 'Good',
    'PASSWORD_STRENGTH_STRONG_TEXT': 'Strong'
  }
}