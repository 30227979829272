import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'FORGOT_PASSWORD_OTP_EXPIRE': '该验证码将在 5 分钟后过期',
    'FORGOT_PASSWORD_OTP_RESEND': '重新发送验证码',
    'FORGOT_PASSWORD_OTP_VERIFY': '验证',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_EMAIL': '电子邮箱已成功验证',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_BUTTON_LABEL': '下一步',
    'FORGOT_PASSWORD_OTP_VERIFICATION_FAIL': '验证码输入错误',
    'FORGOT_PASSWORD_OTP_VERIFICATION_FAIL_BUTTON_LABEL': '再试一次',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_SMS': '手机号码已成功验证'
  },
  'en-gb': {
    'FORGOT_PASSWORD_OTP_EXPIRE': 'This code will expire in 5 minutes',
    'FORGOT_PASSWORD_OTP_RESEND': 'Resend Code',
    'FORGOT_PASSWORD_OTP_VERIFY': 'Verify',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_EMAIL': 'Your code has been successfully verified',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_BUTTON_LABEL': 'Continue',
    'FORGOT_PASSWORD_OTP_VERIFICATION_FAIL': 'Authentication Code Input Error',
    'FORGOT_PASSWORD_OTP_VERIFICATION_FAIL_BUTTON_LABEL': 'Try Again',
    'FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_SMS': 'Mobile number has been successfully verified'
  }
}
