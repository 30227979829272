import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appAutofocusOnEmit]'
})
export class AutofocusOnEmitDirective implements OnDestroy {

  constructor(private elementRef: ElementRef) {

  }

  @Input() selectAllOnFocus: boolean = false;

  @Input()
  public set appAutofocusOnEmit(input$: Observable<boolean> | null) {
    input$?.pipe(
      takeUntil(this.destroy$)
    ).subscribe(shouldFocus => {
      if (shouldFocus) {
        this.elementRef.nativeElement.focus();

        if (this.elementRef.nativeElement.tagName == 'INPUT') {
          // move cursor to the end of the text
          setTimeout(() => {

            const htmlInput = this.elementRef.nativeElement as HTMLInputElement;
            if ( this.selectAllOnFocus ) {
              htmlInput.select();
              
            } else {
              htmlInput.selectionStart = htmlInput.selectionEnd = htmlInput.value.length;
            }
          });
        }
      }
    });
  }

  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
