import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusOnEmitDirective } from './autofocus-on-emit/autofocus-on-emit.directive';



@NgModule({
  declarations: [
    AutofocusOnEmitDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AutofocusOnEmitDirective
  ]
})
export class DirectiveModule { }
