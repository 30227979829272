@if(show) {
    @if(!isValidLength()){
        <span class="password-strength-muted-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_INVALID_TEXT']}}</span>
    }
    @if(isInvalid()){
        <span class="password-strength-muted-bar"></span>
        <span class="password-strength-muted-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_INVALID_TEXT']}}</span>
    }
    @if(isGood()){
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_GOOD_TEXT']}}</span>
    }
    @if(isStrong()){
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_STRONG_TEXT']}}</span>
    }
    @if(!isValidLength()){
        <div class="password-strength-field-error text-small">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_LENGTH_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-small">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_LENGTH_TEXT']}}
        </div>
    }
    @if(!hasNumber()){
        <div class="password-strength-field-error text-small">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_NUMBER_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-small">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_NUMBER_TEXT']}}
        </div>
    }
    @if(!hasLetter()){
        <div class="password-strength-field-error text-small">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_LETTER_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-small">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_LETTER_TEXT']}}
        </div>
    }
    @if(!isPasswordEmpty() && !hasOnlyValidSymbols()){
        <div class="password-strength-field-error text-small">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_SYMBOL_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-info text-small">
            <span class="material-symbols-outlined">info</span> {{langx['PASSWORD_STRENGTH_INFO_SYMBOL_TEXT']}}
        </div>
    }
}