import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HelpCenterItemType, HelpCenterItem } from '../../models/help-center.model';
import { Subject } from 'rxjs';
import { CsChatIconService } from '../../service/cs-chat-icon/cs-chat-icon.service';
import { CommonService } from '../../service/common/common.service';

enum PageType {
  ROOT_PAGE = 0,
  CHILD_PAGE = 1
}

@Component({
  selector: 'app-dynamic-template',
  templateUrl: './dynamic-template.component.html',
  styleUrl: './dynamic-template.component.scss'
})
export class DynamicTemplateComponent implements OnInit, OnDestroy {
  
  destroy$ = new Subject<void>();

  @Input() helpCenterItems: HelpCenterItem[] = [];
  @Input() isTabs: boolean = false;
  @Output() pageTitleChange: EventEmitter<string> = new EventEmitter<string>();  

  HelpCenterItemType = HelpCenterItemType;
  currentTabContents: HelpCenterItem[] = [];
  currentHelpCenterItem: HelpCenterItem = {} as HelpCenterItem;
  previousHelpCenterItems: { [key: number]: HelpCenterItem } = {};
  currentPageType: number = PageType.ROOT_PAGE;

  HelpCenterContentTypes: any = {
    [HelpCenterItemType.SCREEN_REDIRECT]: 'screenRedirectContent',
    [HelpCenterItemType.STATIC_PANEL]: 'staticPanelContent',
    [HelpCenterItemType.COLLAPSING_PANEL]: 'collapsingPanelContent',
    [HelpCenterItemType.CONTACT_CS] : 'contactSupport',
    [HelpCenterItemType.TEXT]: 'textContent',
    [HelpCenterItemType.HEADER] : 'headerContent',
    [HelpCenterItemType.HTML]: 'htmlContent',
    [HelpCenterItemType.BANNER]: 'bannerContent',
  };

  constructor(
    private commonService: CommonService,
    private csChatIconService: CsChatIconService
  ){}

  ngOnInit(): void {
    if(this.isTabs) {
      this.currentTabContents = this.helpCenterItems;
    }
  }

  /**
   * Function that tracks the screen redirections and tracks the previous Help Center Items
   * @param helpCenterItem 
   */
  onScreenRedirectClick(helpCenterItem: any) {
    const helpCenterContentType = this.HelpCenterContentTypes[helpCenterItem.type];
    const helpCenterContent = helpCenterItem[helpCenterContentType];

    // prevent navigating to next page if details is empty
    if(helpCenterContent.details.length > 0) {
      this.trackPreviousHelpCenterItem();
      this.pageTitleChange.emit(helpCenterContent.pageTitle);
      this.currentHelpCenterItem = helpCenterItem;
      this.helpCenterItems = helpCenterContent.details;
      this.nextPage();
    }
  }

  /**
   * Tracks all Previous Help Center Items based on currentPageType
   */
  trackPreviousHelpCenterItem() {
    if(Object.keys(this.currentHelpCenterItem).length > 0) {
      this.previousHelpCenterItems[this.currentPageType] = this.currentHelpCenterItem;
    }
  }

  /**
   * Function that determines if the current page is ROOT PAGE
   * @returns
   */
  isRootPage() {
    return this.currentPageType == PageType.ROOT_PAGE;
  }

  /**
   * Function that populates the previous help center items based on the currentPageType
   * If currentPageType is ROOT PAGE, it should populate the currentTabContents
   * ELSE populate the previous help center items and emit the onPageTitleChange() function
   */
  previous() {
    this.previousPage();
    if(this.isRootPage()) {
      this.helpCenterItems = this.currentTabContents;
    } else {
      const previousHelpCenterItem: any = this.previousHelpCenterItems[this.currentPageType];
      if(previousHelpCenterItem) {
        this.currentHelpCenterItem = previousHelpCenterItem;
        const helpCenterItemType = this.HelpCenterContentTypes[previousHelpCenterItem.type];
        this.pageTitleChange.emit(previousHelpCenterItem[helpCenterItemType].pageTitle);
        this.helpCenterItems = previousHelpCenterItem[helpCenterItemType].details;
      }
    }
  }

  /**
   * Increases the currentPageType + 1 
   * currentPageType = 0 | Equivalent to ROOT PAGE
   * currentPageType = 1 | Equivalent to CHILD PAGE
   * currentPageType > 1 | Equivalent to SUB CHILD PAGES
   */
  nextPage() {
    this.currentPageType += 1;
  }

  /**
   * Decreases the currentPageType - 1 
   */
  previousPage() {
    this.currentPageType -= 1;
  }

  onContactCsClick() {
    this.commonService.showLoading();
    this.csChatIconService.getCsChatUrl().subscribe(
      response => {
        this.commonService.hideLoading();
        window.open(response.data.ktoCsUrl, '_blank');
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
