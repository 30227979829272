<div class="collapsible-panel">
    <div class="collapsible-content" (click)="togglePanel()">
        <div class="content-flex">
            <p class="collapsible-title">{{ title }}</p>
            <span class="material-symbols-outlined arrow-forward">{{ isOpen ? 'remove' : 'add' }}</span>
        </div>

        @if(isOpen) {
            <div class="collapsible-panel-content">
                <p class="collapsible-message">{{ message }}
                </p>
            </div>
        }

    </div>
</div>