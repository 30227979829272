@if(modalContent$ | async; as content) {
<div class="modal-wrapper backdrop" [class.show]="(modalDisplayToggle$ | async) == true">
    <div class="modal">
        <div class="modal-header">
            <div class="text header">{{ langx[content.title] || content.title }}</div>
        </div>

        <div class="modal-body">
            <div class="icon">
                <img [src]="content.imageUrl">
            </div>

            <div class="message text-small">
                {{ content.message }}
            </div>

            @for (button of content.buttons; track $index) {
            <div class="btn-wrapper">
                <input (click)="onModalButtonClick($event, button)" class="btn btn-{{button.type}} text-small" type="button" [value]="langx[button.label] || button.label" />
            </div>
            }
        </div>

        <div class="modal-footer">
        </div>
    </div>
</div>
}