export interface PuzzleCaptchaContent {
  title: string;
  info: string;
  state: PuzzleCaptchaState;
  boardImage: string;
  pieceImage: string;
  width: number;
  token: string;
  sliderKnobPosX: number;
  puzzlePiecePosX: number;
  isMouseDown: boolean;
  originX: number;
  originY: number;
  trail: Array<number>;
}

export enum PuzzleCaptchaState {
  Default,
  Tried,
  Loading,
  Verifying,
  Active,
  Success,
  Fail
}

export type PuzzleCaptchaEventType = 'onSuccess' | 'onClose' | 'onOpen';

export interface PuzzleCaptchaEvent {
  verificationStub: string | undefined;
  type: PuzzleCaptchaEventType
}