import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from '../../service/common/common.service';
import { CsChatIconService } from '../../service/cs-chat-icon/cs-chat-icon.service';

@Component({
  selector: 'app-cs-chat-icon',
  templateUrl: './cs-chat-icon.component.html',
  styleUrl: './cs-chat-icon.component.scss'
})
export class CsChatIconComponent implements OnInit {

  private destroy$ = new Subject<void>();
  mediaServer: string = '';
  url: string = '';

  constructor(
    private commonService: CommonService,
    private csChatIconService: CsChatIconService
  ) {
    this.commonService.mediaServer$.subscribe(mediaServer => {
      this.mediaServer = mediaServer;
    });
  }

  ngOnInit(): void {
    this.loadUrl();
  }

  onCsChatIconClick() {
    window.open(this.url, '_blank');
  }

  loadUrl() {
    this.csChatIconService.getCsChatUrl().subscribe(
      response => this.url = response.data.ktoCsUrl
    );
  }

}
