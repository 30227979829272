import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, first } from 'rxjs';
import { ModalContent } from '../../models/modal.model';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalContent: Subject<ModalContent> = new Subject<ModalContent>();
  modalContent$: Observable<ModalContent> = this.modalContent.asObservable();

  private modalViewToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modalDisplayToggle$: Observable<boolean> = this.modalViewToggle.asObservable();

  constructor(
    private commonService: CommonService
  ) {
    this.commonService.mediaServer$.subscribe(mediaServer => {
      this.mediaServer = mediaServer;
      
    });
  }

  mediaServer: string = '';

  showError(message: string, label: string = 'LABEL_CLOSE') {
    const modalClose$: Subject<boolean> = new Subject<boolean>();

    this.modalContent.next({
      title: 'ERROR',
      imageUrl: this.mediaServer + '/zeta/common/icon_circle-error.png',
      message: message,
      buttons: [{
        label: label,
        type: 'primary',
        callback: () => {
          this.closeModal();
          modalClose$.next(true);
        }
      }]
    });

    this.openModal();
    return modalClose$.asObservable().pipe(first());
  }

  /**
   * 
   * @param message 
   * @returns modalClose$: emits when close is clicked
   */
  showInformation(message: string,  label: string = 'LABEL_CLOSE') {
    const modalClose$ = new Subject<any>();

    this.modalContent.next({
      title: 'INFORMATION',
      imageUrl: this.mediaServer + '/zeta/common/icon_information.png',
      message: message,
      buttons: [{
        label: label,
        type: 'primary',
        callback: () => {
          this.closeModal();
          modalClose$.next(1);
        }
      }]
    });

    this.openModal();
    return modalClose$.asObservable().pipe(first());
  }

  /**
   * 
   * @param message 
   * @returns modalClose$: emits when close is clicked
   */
  showSuccess(message: string, label: string = 'LABEL_CLOSE') {
    const modalClose$ = new Subject<any>();

    this.modalContent.next({
      title: 'SUCCESS',
      imageUrl: this.mediaServer + '/zeta/common/icon_circle-success.png',
      message: message,
      buttons: [{
        label: label,
        type: 'primary',
        callback: () => {
          this.closeModal();
          modalClose$.next(0);
        }
      }]
    });
    this.modalViewToggle.next(true);
    return modalClose$.asObservable().pipe(first());
  }

  /**
   * shows the confirmation dialog with two options: yes or no
   * returns an observable that emits when 'yes' is clicked 
   * 
   * @param message 
   * @returns modalConfirmed$ emits true or false when yes or no is clicked
   */
  showConfirmation(message: string,
    proceedLabel: string = 'LABEL_YES',
    cancelLabel: string = 'LABEL_NO',
    title: string = 'CONFIRMATION'
  ): Observable<any> {
    const modalConfirmed$ = new Subject<any>();

    this.modalContent.next({
      title: title,
      imageUrl: this.mediaServer + '/zeta/common/icon_confirmation.png',
      message: message,
      buttons: [
        {
          type: 'primary',
          label: proceedLabel,
          callback: () => {
            modalConfirmed$.next(true);
            this.closeModal();
          }
        },
        {
          type: 'secondary',
          label: cancelLabel,
          callback: () => {
            modalConfirmed$.next(false);
            this.closeModal();
          }
        }
      ]
    });

    this.modalViewToggle.next(true);
    return modalConfirmed$.asObservable().pipe(first());
  }

  openModal() {
    this.modalViewToggle.next(true);
  }

  closeModal() {
    this.modalViewToggle.next(false);
  }

  preloadImages() {
    [
      this.mediaServer + '/zeta/common/icon_circle-error.png',
      this.mediaServer + '/zeta/common/icon_information.png',
      this.mediaServer + '/zeta/common/icon_circle-success.png',
      this.mediaServer + '/zeta/common/icon_confirmation.png'
    ].map(src => {
      (new Image()).src = src;
    });
  }
}
