import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from '../../service/common/common.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss'
})
export class BackButtonComponent {

  backButtonViewToggle$: Observable<boolean>;
  
  constructor(
    private commonService: CommonService
  ) {
    this.backButtonViewToggle$ = commonService.backButtonViewToggle$;
  }

  onBackButtonClick() {
    this.commonService.emitBackButtonClickSignal();
  }
}
