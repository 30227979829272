export enum HelpCenterItemType {
  SCREEN_REDIRECT = 'screen_redirect',
  COLLAPSING_PANEL = 'collapsing_panel',
  STATIC_PANEL = 'static_panel',
  CONTACT_CS = 'contact_cs',
  HEADER = 'header',
  TEXT = 'text',
  HTML = 'html',
  BANNER = 'banner'
}

interface ScreenRedirectContent {
  pageTitle: string;
  details: HelpCenterItem[];
  title: string;
}

interface CollapsingPanelContent {
  title: string;
  message: string;
}

interface StaticPanelContent {
  details: HelpCenterItem[];
  title: string;
}

interface HeaderContent {
  header: string
}

interface TextContent {
  text: string
}

interface HtmlContent {
  html: string
}

interface BannerContent {
  bannerUrl: string
}

export interface HelpCenterItem {
  collapsingPanelContent?: CollapsingPanelContent;
  screenRedirectContent: ScreenRedirectContent;
  staticPanelContent?: StaticPanelContent;
  headerContent?: HeaderContent
  textContent?: TextContent
  htmlContent?: HtmlContent
  bannerContent?: BannerContent
  title?: string;
  type: string;
}

export interface HelpCenterItems {
  faqs: HelpCenterItem[],
  quickLinks: HelpCenterItem[],
  tabs: HelpCenterItem[]
}