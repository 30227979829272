// env for production
export const environment = {
  production: true,

  // production gets the initial base url from client-api (thru gateway)
  wapInitialBaseUrl: '',

  // by default all api is pointed to uat
  domains: {
    clientApiService: 'https://zetaclientapi.wellbettycoon.info',
    integrationService: 'https://zetaintservice.wellbettycoon.info',
    wellbetService: 'https://mobileweb.wellbettycoon.info',
    mediaServer: 'https://media.jxf88.co',
    higgsServer: 'zhesy.rwbtcic.info'
  }
}