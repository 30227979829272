import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../models/http.model';

@Injectable({
  providedIn: 'root'
})
export class CsChatIconService {

  constructor(
    private httpService: HttpService
  ) { }

  getCsChatUrl(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/system/cs/chat/url');
  }
  
}
