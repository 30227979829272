import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { CommonService } from '../../service/common/common.service';

@Component({
  selector: 'app-inline-preloader',
  templateUrl: './inline-preloader.component.html',
  styleUrl: './inline-preloader.component.scss'
})
export class InlinePreloaderComponent implements OnInit {
  mediaServer: string = '';

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.commonService.domains$.pipe(first()).subscribe(domains => {
      this.mediaServer = domains.mediaServer;
    });
  }
}
