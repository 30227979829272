import { Component, OnInit } from '@angular/core';
import { Observable, first } from 'rxjs';
import { CommonService } from '../../service/common/common.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrl: './preloader.component.scss'
})
export class PreloaderComponent implements OnInit {

  mediaServer: string = '';

  loadingState$: Observable<boolean>;

  constructor(private commonService: CommonService) {
    this.loadingState$ = commonService.loadingState$;
  }

  ngOnInit(): void {
    this.commonService.domains$.pipe(first()).subscribe(domain => {
      this.mediaServer = domain.mediaServer;
    });
  }
}
