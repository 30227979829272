import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, first, map, merge } from 'rxjs';
import { BaseResponse } from '../../models/http.model';
import { PuzzleCaptchaEvent } from '../../models/puzzle-captcha.model';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class PuzzleCaptchaService {

  private puzzleDisplayToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  puzzleDisplayToggle$: Observable<boolean> = this.puzzleDisplayToggle.asObservable();

  private verificationStub: Subject<string> = new Subject<string>();
  verificationStub$: Observable<string> = this.verificationStub.asObservable();

  constructor(private httpService: HttpService) {
  }

  retrievePuzzleImage(): Observable<BaseResponse> {
    const timestamp = new Date().getTime().toString(); // used for cache busting (cb)
    return this.httpService.forClientApi().get(`/puzzle-captcha?&cb=${timestamp}`);
  }

  verifyPuzzleCaptcha(trans: string): Observable<BaseResponse> {
    return this.httpService.forClientApi().post('/puzzle-captcha/verify', { trans: trans });
  }

  setVerificationStub(verificationStub: string): void {
    this.verificationStub.next(verificationStub);
    this.hidePuzzleCaptcha();
  }

  showPuzzleCaptcha(): Observable<PuzzleCaptchaEvent> {
    this.verificationStub.next('');
    this.puzzleDisplayToggle.next(true);

    const isSuccess = (value: string | boolean): boolean => {
      return (typeof value == 'string' && value != '' && this.puzzleDisplayToggle.getValue() == true);
    }

    const isClosed = (value: string | boolean): boolean => {
      return (typeof value == 'boolean' && value == false)
    }

    return merge(
      this.puzzleDisplayToggle$, this.verificationStub$
    ).pipe(
      filter((value) => {
        return (isSuccess(value) || isClosed(value));
      }),
      first(),
      map(value => {
        if (isSuccess(value)) { // puzzleDisplayToggle$ emitted false
          return {
            type: 'onSuccess',
            verificationStub: value
          } as PuzzleCaptchaEvent;

        } else if (isClosed(value)) {
          return {
            type: 'onClose',
            verificationStub: undefined
          } as PuzzleCaptchaEvent;

        } else {
          return {
            type: 'onOpen',
            verificationStub: undefined
          } as PuzzleCaptchaEvent;
        }
      })
    );
  }

  hidePuzzleCaptcha(): void {
    this.puzzleDisplayToggle.next(false);
  }

}
