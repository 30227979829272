@if(puzzleCaptchaContent$ | async; as content) {
  <div class="puzzle-captcha-wrapper backdrop" [class.show]="(puzzleDisplayToggle$ | async) == true">
    <div class="puzzle-captcha-modal">

      @if(isLoading()){

        <div class="puzzle-captcha-content">
          <img class="puzzle-captcha-placeholder-image" src="{{ mediaServer }}/zeta/common/placeholder_puzzle-captcha.png" />
        </div>

      } @else {

        <div class="text header puzzle-captcha-header"> {{ content.title }}
          <div class="right-controls">
            <div class="reload-btn" (click)="onPuzzleCaptchaRefreshButtonClick()">
              <img src="{{ mediaServer }}/wapv5/icons/Reflash_o.svg" />
            </div>
            <div class="close-btn" (click)="onPuzzleCaptchaCloseButtonClick()">
              <img src="{{ mediaServer }}/wapv5/icons/close_o.svg" />
            </div>
          </div>
        </div>

        <div class="puzzle-captcha-content">

          <img class="puzzle-captcha-board-image" [src]="content.boardImage" [hidden]="isVerifying()" />
          <img class="puzzle-captcha-piece-image" [src]="content.pieceImage" [hidden]="isVerifying()"
            [ngStyle]="{'left': content.puzzlePiecePosX + 'px'}" />

          <div class="slider-container"
            [ngClass]="{ 'slider-container-active': isActive(), 'slider-container-success': isSuccess(), 'slider-container-fail': isFailed() }">
            <div class="slider-bg"></div>
            <span class="slider-text"></span>

            <div class="slider-float-text text-very-small">
              {{ content.info }}
            </div>

            <div class="slider-mask" [ngStyle]="{'width': (content.width - (content.width - content.sliderKnobPosX) + 30) + 'px'}">
              <div class="slider"
                [ngStyle]="{'left': content.sliderKnobPosX +'px'}"
                (mousedown)="dragPuzzleCaptchaStart($event);"
                (touchstart)="dragPuzzleCaptchaStart($event);"
                (mouseup)="dragPuzzleCaptchaEnd();"
                (mousemove)="dragPuzzleCaptchaMove($event);"
                (touchmove)="dragPuzzleCaptchaMove($event);"
                (touchend)="dragPuzzleCaptchaEnd();"
              >

                <div class="icon">
                  <img src="{{ mediaServer }}/wapv5/icons/verification_success_white.svg" [hidden]="!isSuccess()" />
                  <img src="{{ mediaServer }}/wapv5/icons/verification_failure_white.svg" [hidden]="!isFailed()" />
                  <img src="{{ mediaServer }}/wapv5/icons/verification_black_line.svg" [hidden]="!isActive()" />
                </div>

              </div>
            </div>
          </div>
          
        </div>

      }

    </div>
  </div>
}
