

@for (helpCenterItem of helpCenterItems; track $index) {
    <ng-container [ngTemplateOutlet]="dynamicTemplate"
        [ngTemplateOutletContext]="{item: helpCenterItem}">
    </ng-container>
}

<ng-template #dynamicTemplate let-item="item">
    @switch (item.type) {
        @case (HelpCenterItemType.SCREEN_REDIRECT) {
            <ng-container [ngTemplateOutlet]="screenRedirectTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.COLLAPSING_PANEL) {
            <ng-container [ngTemplateOutlet]="collapsingPanelTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.STATIC_PANEL) {
            <ng-container [ngTemplateOutlet]="staticPanelTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.CONTACT_CS) {
            <ng-container [ngTemplateOutlet]="contactCsTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.HEADER) {
            <ng-container [ngTemplateOutlet]="headerTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.TEXT) {
            <ng-container [ngTemplateOutlet]="textTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.BANNER) {
            <ng-container [ngTemplateOutlet]="bannerTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @case (HelpCenterItemType.HTML) {
            <ng-container [ngTemplateOutlet]="htmlTemplate"
                [ngTemplateOutletContext]="{item: item}">
            </ng-container>
        }
        @default {}
    }
</ng-template>

<!-- Dynamic Templates -->
<ng-template #screenRedirectTemplate let-item="item">
    <div class="dynamic-content screen-redirect">
        <div class="content-flex-container" (click)="onScreenRedirectClick(item)">
            <p class="content-title">{{ item.screenRedirectContent?.title }}</p>
            <span class="material-symbols-outlined arrow-forward">navigate_next</span>
        </div>
    </div>
</ng-template>

<ng-template #contactCsTemplate let-item="item">
    <div class="dynamic-content contact-cs">
        <div class="content-flex-container" (click)="onContactCsClick()">
            <span class="content-title">{{ item.title }}</span>
            <p class="content-description">Ask questions to live chat</p>
            <span class="material-symbols-outlined cs-arrow-forward">navigate_next</span>
        </div>
    </div>
</ng-template>

<ng-template #collapsingPanelTemplate let-item="item" >
    <app-collapsible-panel 
        [title]="item.collapsingPanelContent?.title" [message]="item.collapsingPanelContent?.message">
    </app-collapsible-panel>
</ng-template>

<ng-template #staticPanelTemplate let-item="item">
    <div class="static-panel" >
        <div class="content-flex">
            @for (item of item.staticPanelContent.details; track $index) {
                <ng-container [ngTemplateOutlet]="dynamicTemplate"
                    [ngTemplateOutletContext]="{item: item}">
                </ng-container>
            }
        </div>
    </div>
</ng-template>

<ng-template #headerTemplate let-item="item">
    <div class="header-content">
        <span class="header">{{ item.headerContent?.header }}</span>
    </div>
</ng-template>

<ng-template #textTemplate let-item="item">
    <div class="text-content">
        @if(item.textContent?.bullet) {
            <span class="bullet-text">{{ item.textContent?.bullet }}</span>
        }
        <span class="text">{{ item.textContent?.text }}</span>
    </div> 
</ng-template>

<ng-template #bannerTemplate let-item="item">
    <div class="banner-content">
        <img class="banner" src="{{ item.bannerContent?.bannerUrl }}">
    </div>
</ng-template>

<ng-template #htmlTemplate let-item="item">
    <div class="html-content">
        @if (item.htmlContent?.bullet) {
            <span class="bullet-text">{{ item.htmlContent?.bullet }}</span>
        }
        <span class="text" [innerHTML]="item.htmlContent?.html"></span>
    </div>
</ng-template>