import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseResponse } from '../../../../models/http.model';
import { HelpCenterItemType, HelpCenterItem } from '../../../../models/help-center.model';

@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {

  private pageTitleSubject = new BehaviorSubject<string>('');
  pageTitle$ = this.pageTitleSubject.asObservable();

  private titleSubject = new BehaviorSubject<string>('');
  title$ = this.titleSubject.asObservable();

  private descriptionSubject = new BehaviorSubject<string>('');
  description$ = this.descriptionSubject.asObservable();

  private isNavigationItemsVisibleSubject = new BehaviorSubject<boolean>(true);
  isNavigationItemsVisible$ = this.isNavigationItemsVisibleSubject.asObservable();

  private backButtonClickSubject = new Subject<void>();
  backButtonClick$ = this.backButtonClickSubject.asObservable();

  constructor(
    private httpService: HttpService
  ) { }

  getContactUs(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/help-center/contact-us');
  }

  getGameIssues(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/help-center/game-issues')
  }

  getCorporateAffairs(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/help-center/corporate-affairs')
  }

  setPageTitle(pageTitle: string) {
    this.pageTitleSubject.next(pageTitle);
  }

  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  setDescription(description: string) {
    this.descriptionSubject.next(description);
  }

  showNavigationItems() {
    this.isNavigationItemsVisibleSubject.next(true);
  }
  
  hideNavigationItems() {
    this.isNavigationItemsVisibleSubject.next(false);
  }

  mapDataToHelpCenterItems(data: any): HelpCenterItem[] {
    return data.map((content: any) => {
      switch (content.type) {
        case HelpCenterItemType.SCREEN_REDIRECT:
          return { screenRedirectContent: content.screenRedirectContent, type: content.type };
        case HelpCenterItemType.COLLAPSING_PANEL:
          return { collapsingPanelContent: content.collapsingPanelContent, type: content.type };
        case HelpCenterItemType.STATIC_PANEL:
          return { staticPanelContent: content.screenRedirectContent, type: content.type };
        case HelpCenterItemType.CONTACT_CS:
          return { title: content.title, type: content.type };
        case HelpCenterItemType.HEADER:
          return { headerContent: content.headerContent, type: content.type };
        case HelpCenterItemType.TEXT:
          return { textContent: content.textContent, type: content.type };
        case HelpCenterItemType.HTML:
          return { htmlContent: content.htmlContent, type: content.type };
        case HelpCenterItemType.BANNER:
          return { bannerContent: content.bannerContent, type: content.type };
        default:
          throw new Error(`Unknown tab type: ${content.type}`);
      }
    });
  }

  setTitleAndDescription(pageTitle: string, title: string, description: string) {
    this.setPageTitle(pageTitle);
    this.setTitle(title);
    this.setDescription(description);
  }

  emitBackButtonClickSignal() {
    this.backButtonClickSubject.next();
  }

}
