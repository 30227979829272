import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../models/i18n.model';
import { CommonService } from '../../service/common/common.service';
import { langxObj } from './password-strength_langx';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrl: './password-strength.component.scss'
})
export class PasswordStrengthComponent implements OnDestroy {

  @Input() password: string = '';
  @Input() show: boolean = false;

  private destroy$ = new Subject<void>();
  currentLanguage: string = '';
  langx: LocalizedMessages = {};

  constructor(private commonService: CommonService){
    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasNumber(): boolean{
    return /\d/.test(this.password);
  }

  hasLetter(): boolean{
    return /[a-zA-Z]/.test(this.password);
  }

  hasOnlyValidSymbols(): boolean{
    return /^[a-zA-Z\d#@!~%^&*]+$/.test(this.password);
  }

  hasSymbols(): boolean{
    return /[^\w\s]/.test(this.password);
  }

  isValidLength(): boolean{
    return this.password.length >= 6 && this.password.length <= 30;
  }

  isPasswordEmpty(): boolean{
    return this.password.length < 1;
  }

  isInvalid(): boolean {
    return this.isValidLength() && (
      !this.hasNumber() || 
      !this.hasLetter() || 
      !this.hasOnlyValidSymbols()
    )
  }

  isGood(): boolean {
    return (
      this.isValidLength() &&
      !this.isInvalid() &&
      !this.hasSymbols()
    )
  }

  isStrong(): boolean{
    return (
      this.isValidLength() &&
      !this.isInvalid() &&
      this.hasSymbols() &&
      this.hasOnlyValidSymbols()
    )
  }

}
