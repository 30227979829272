import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapsible-panel',
  templateUrl: './collapsible-panel.component.html',
  styleUrl: './collapsible-panel.component.scss'
})
export class CollapsiblePanelComponent {

  @Input() title: string = '';
  @Input() message: string = '';
  isOpen: boolean = false;

  togglePanel() {
    this.isOpen = !this.isOpen;
  }
  
}
