import { LocalizedMessagesBundle } from '../../models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'PUZZLE_CAPTCHA_TITLE': 'WELLBET 验证',
    'PUZZLE_CAPTCHA_LOADING_TEXT': '加载中...',
    'PUZZLE_CAPTCHA_INFO_TEXT': '拖动滑块来完成拼图',
    'PUZZLE_CAPTCHA_TRY_AGAIN_TEXT': '再试一次',
    'PUZZLE_CAPTCHA_MAX_RETRIES_REACHED':'验证失败，请10分钟后重试。'
  },
  'en-gb': {
    'PUZZLE_CAPTCHA_TITLE': 'WELLBET 验证',
    'PUZZLE_CAPTCHA_LOADING_TEXT': 'Loading ...',
    'PUZZLE_CAPTCHA_INFO_TEXT': 'Drag the slider to complete the puzzle',
    'PUZZLE_CAPTCHA_TRY_AGAIN_TEXT': 'Try again',
    'PUZZLE_CAPTCHA_MAX_RETRIES_REACHED': 'Sorry, the verification failed, please try again after 10 minutes.'
  }
}