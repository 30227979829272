import { LocalizedMessagesBundle } from '../../models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'SUCCESS': '成功',
    'ERROR': '错误',
    'INFORMATION': '信息',
    'CONFIRMATION': '确认',
    'LABEL_CLOSE': '关闭',
    'LABEL_YES': '是的',
    'LABEL_NO': '不',
    'LABEL_CANCEL': '取消',
  },
  'en-gb': {
    'SUCCESS': 'Success',
    'ERROR': 'Error',
    'INFORMATION': 'Information',
    'CONFIRMATION': 'Confirmation',
    'LABEL_CLOSE': 'Close',
    'LABEL_YES': 'Yes',
    'LABEL_NO': 'No',
    'LABEL_CANCEL': 'Cancel',
  }
}