import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HomeNavigationPage, LiveStream, SendOtpDto, VerifyOtpDto } from '../../models/home.model';
import { BaseResponse } from '../../models/http.model';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private bottomNavigationViewToggle = new BehaviorSubject<boolean>(true);
  bottomNavigationViewToggle$ = this.bottomNavigationViewToggle.asObservable();

  constructor(
    private httpService: HttpService
  ){
  }

  private isFromRegister = new ReplaySubject<boolean>(1);
  isFromRegister$ = this.isFromRegister.asObservable();

  private currentPage = new Subject<HomeNavigationPage>();
  currentPage$ = this.currentPage.asObservable();

  private liveStreamList = new Subject<Array<LiveStream>>();
  liveStreamList$ = this.liveStreamList.asObservable();

  private shouldCheckChatSearch = new BehaviorSubject<boolean>(false);
  shouldCheckChatSearch$ = this.shouldCheckChatSearch.asObservable();

  setCurrentPage(currentPage: HomeNavigationPage) {
    this.currentPage.next(currentPage);
  }

  /**
   * Hides the bottom navigation part of the page.
   * Useful when we want a blank white page
   */
  hideBottomNavigation(): void {
    this.bottomNavigationViewToggle.next(false);
  }

  /**
   * Shows the bottom navigation part of the page.
   */
  showBottomNavigation(): void {
    this.bottomNavigationViewToggle.next(true);
  }

  getLiveStreamListWithKeyword(keyword: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/match/info', {
      league: keyword,
      homeTeam: keyword,
      awayTeam: keyword,
      page: 1,
      countPerPage: 10 // TODO change later
    });
  }

  getLiveStreamList(page: number = 1, countPerPage: number = 10, isLive: boolean = true): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/match/info', {
      page,
      countPerPage,
      nowPlaying: isLive
    });
  }

  getAnnouncementList(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/announcements');
  }

  setLiveStreamList(list: Array<LiveStream>): void {
    this.liveStreamList.next(list);
  }

  getLiveStreamLink(matchId: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().get('/user/stream/live/' + matchId);
  }

  getLiveStreamDetails(matchId: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/match/info', {
      page: 1,
      countPerPage: 1,
      matchId
    });
  }

  sendOtp(sendOtpDto: SendOtpDto): Observable<BaseResponse<any>> {
    return this.httpService.forIntegrationService().post('/user/otp/send', sendOtpDto);
  }

  verifyOtp(verifyOtpDto: VerifyOtpDto): Observable<BaseResponse<any>> {
    return this.httpService.forClientApi().post('/otp/validate', verifyOtpDto);
  }

  getQRCode(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/account/share-link');
  }

  setIsFromRegisterTrue() {
    this.isFromRegister.next(true);
  }

  setIsFromRegisterFalse() {
    this.isFromRegister.next(false);
  }

  getRegexBlocking() {
    return this.httpService.forClientApi().get('/system/config/chat-blocking-filter-regex');
  }

  setShouldCheckChatSearch(value: boolean) {
    this.shouldCheckChatSearch.next(value);
  }
}
