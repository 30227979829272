import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrl: './marquee.component.scss'
})
export class MarqueeComponent implements AfterViewChecked {

  @Input() playToggle$: Observable<boolean> = new BehaviorSubject<boolean>(true);
  @Input() cssClass: string = '';

  isPlaying: boolean = false;
  animationSpeed: number = 50;

  @ViewChild('container') elementRef!: ElementRef;
  
  ngAfterViewChecked(): void {
    const offsetWidth = this.elementRef.nativeElement.offsetWidth;

    if(offsetWidth > 0 && !this.isPlaying) {
      const timeTaken = offsetWidth / this.animationSpeed;
      console.log('timeTaken', offsetWidth, timeTaken);
      this.elementRef.nativeElement.style.animationDuration = timeTaken + 's';
      this.isPlaying = true;
    }
  }

}
