import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../../../models/http.model';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  constructor(
    private httpService: HttpService
  ) { }

  getTermsAndConditions(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/terms-and-conditions');
  }
  
}
